import React, { useState, useEffect } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import FourOhFour from '../display/fourOhFour.js';
import urls from '../../utility/url';

export default function Signup(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [unitNo, setUnitNo] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [quadrant, setQuadrant] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const history = useHistory();
  const { id } = useParams();

  // Similar to componentDidMount and componentDidUpdate:
  // only update when id is changed
  useEffect(() => {
    props.updateCurURL(id);
  },[id]);

  function validateForm() {
    const passwordValidation = (password.length > 0) && (password === confirmpassword);
    return email.length > 0 && passwordValidation > 0 && firstName.length > 0;
  }

  // "Generate client secret" option is disabled at creating User Pool for app client
  async function handleSignUp(event) {
    event.preventDefault();
    try {
      const newUser = await Auth.signUp({
        username: email,
        password: password,
      });
      setErrormsg("Registered successfully!");
    } catch (e) {
      setErrormsg(`Registration Failed: ${e.message}`);
    }
  }

  let display; 

  if (props.isCustomer) {
    display = 
    <Container>
      <form onSubmit={handleSignUp}>
        <div className="paddingTop"></div>
        <FormGroup controlId="email" bssize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bssize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmpassword" bssize="large">
          <FormLabel>Confirm Password</FormLabel>
          <FormControl
            value={confirmpassword}
            onChange={e => setConfirmPassword(e.target.value)}
            type="password"
          />
        </FormGroup>      
        <FormGroup controlId="firstName" bssize="large">
          <FormLabel>First Name</FormLabel>
          <FormControl
            autoFocus
            type="firstName"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </FormGroup>
        <div className="loginerror">
          {errormsg}
        </div>
        <Button className="submitBtn" block bssize="large" disabled={!validateForm()} type="submit">
          Sign Up
        </Button>
        <br/>
        <span>or</span>
        <br/>
        <br/>
        <Link to={urls.userAdminLogin.replace(":id",id)}>
          <Button id="login" className="submitBtn" block bssize="large" type="submit">
            Login
          </Button>
        </Link>            
        <br/>
      </form>
    </Container>;
  } else {
    display = <div><FourOhFour/></div>;
  }
  
  return (
    <div className="page Login">
      {display}    
    </div>
  );
}