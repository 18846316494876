import config from './config.js';
import { get, getSelection } from './utility.js';

function saveAttendance(clientProfile) {
    const customer = {};
    customer.First_Name = get("FirstNID")
    customer.Last_Name = get("LastNID")
    customer.Phone_Number = get("PhoneID")
    customer.Email = get("EmailID")
    customer.Reason = get("ReasonID")
    customer.Symptoms1 = getSelection("SymptomsID1")
    customer.Symptoms2 = getSelection("SymptomsID2")
    customer.Symptoms3 = getSelection("SymptomsID3")
    customer.Symptoms4 = getSelection("SymptomsID4")
    customer.Travel = getSelection("TravelID")
    customer.Distancing = getSelection("DistancingID")

    customer.CompanyID = clientProfile["CompanyID"];
    customer.DisplayName = clientProfile["DisplayName"];

    let counter=0;
    for(let key in customer) {
        
        if (customer[key] === "not valid") {
            counter++
        }          
    }

    if (counter === 0) {
        // delete customer.counter
        postData(config.apiGateway.URL+"/customer", customer)
            .then(data => {
                // console.log(data); // JSON data parsed by `data.json()` call
            });
    } else {
        document.getElementById("completeForm").textContent = "Complete required sections!" 
        let node= document.createElement("br")
        let node2= document.createElement("br")
        document.getElementById("completeForm").appendChild (node)
        document.getElementById("completeForm").appendChild (node2)
    }
    return [customer,counter];
};

async function getAttendance(query) {
    const data = postData(config.apiGateway.URL+"/client", query)
    return data;
}

async function addNewClient(data) {
    const result = postData(config.apiGateway.URL+"/newclient", data);
    return result;
}

async function getClient() {
    const response = await fetch(config.apiGateway.URL+"/newclient")
    const data= await response.json()   
    return data;
}

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    const responseQuery = await response.json()
    return responseQuery; // parses JSON response into native JavaScript objects
}

export { getAttendance, postData, saveAttendance, addNewClient, getClient };