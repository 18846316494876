import React from "react";
import './home.css';
import SlideShow from '../../containers/control/slideshow'
import NewClient from '../../containers/display/newclient'
import Disclaimer from '../../containers/control/disclaimer'
import BGPic from '../../image/background.jpg';
import PhonePic from '../../image/phoneapp.png';
import BusinessPic from '../../image/open-for-business.jpg';
import HassleFreePic from '../../image/hassle-free.jpg';
import NoCovidPic from '../../image/covid-19-free.jpg';
import ManufacturePic from '../../image/Manufacture.jpg';
import OfficePic from '../../image/Office.jpg';
import RestaurantPic from '../../image/Restaurant .jpg';
import RetailPic from '../../image/Retail.jpg';
import SchoolPic from '../../image/School.jpg';
import SportsPic from '../../image/Sports.jpg';
import { container } from "aws-amplify";
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import DefaultPage from '../../components/display/defaultpage.js';

class Home extends React.Component {
        
    componentDidMount() {
        this.props.setHome(true);

        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    handleScroll () {
        const invisible = document.querySelectorAll(".invisible");                
        
        for (let i=0; i<invisible.length; i++)
        {
            const el = invisible[i];                   

            if(window.scrollY > el.offsetTop) {
                el.setAttribute("class","fade-in");              
            }
        }

        const fadein = document.querySelectorAll(".fade-in");
        for (let i=0; i<fadein.length; i++)
        {
            const el = fadein[i];               
        
            if(window.scrollY < el.offsetTop) {
                el.setAttribute("class","invisible");
            }
        }
    }

    // https://www.caktusgroup.com/blog/2017/10/23/css-tip-fixed-headers-and-section-anchors/
    render() {

        const slideshowContent = [
            {
                "content": <img src={ManufacturePic}></img>,
                "captiontext": "Manufacturing"
            },
            {
                "content": <img src={OfficePic}></img>,
                "captiontext": "Office Spaces"
            },
            {
                "content": <img src={RestaurantPic}></img>,
                "captiontext": "Restaurants and Pubs"
            },
            {
                "content": <img src={RetailPic}></img>,
                "captiontext": "Retail"
            },
            {
                "content": <img src={SchoolPic}></img>,
                "captiontext": "Education Centres and Daycares"
            },
            {
                "content": <img src={SportsPic}></img>,
                "captiontext": "Sports and Pubic Events"
            }                                                                                                            
        ]

        return (
            <div>
                <div className="homebody">
                    <div>
                        <div className="homeanchor">
                            <a name="home"> </a> 
                                               
                            <div className="homeBG">
                            {/* <div> */}
                             {/* <p>Let's work together to stop the spread of COVID-19 </p> */}
                             
                             <a  href="#signup">
                                 
                                    <div className="ovalbutton">
                                    {/* <p>Let's work together to stop the spread of COVID-19</p> */}
                                       Sign Up
                                       </div>     
                             </a>
                             <a  href="#signup">
                                 
                                    <div className="ovalbutton2">
                                    {/* <p>Let's work together to stop the spread of COVID-19</p> */}
                                       Client Page
                                       </div>     
                             </a>
                             <div className="content" >
                             Let's work together to stop the spread of COVID-19.
                           
                             </div>
                             
                            {/* </div> */}
                                <img  src={BGPic} alt="Woman in Mask"></img>                        
                            </div>                        
                        </div>
                        <div className="homeanchor">
                            <a name="goals"> </a>
                            <div className="homesection">
                                <h1>Goals</h1>
                                <div className="homebulletwrap">
                                    <div className="homebulletin">
                                        <div className="bulletBG">
                                            <img src={BusinessPic} alt="Open for Business"></img> 
                                        </div>
                                        <div>
                                            Support businesses safely re-open and manage Covid-19 Risk
                                        </div>                                    
                                    </div>
                                    <div className="homebulletin">
                                        <div className="bulletBG">
                                            <img src={HassleFreePic} alt="Hassle Free"></img> 
                                        </div>
                                        <div>
                                            Allow Covid-19 tracing to be faster and easier
                                        </div>                                    
                                    </div>                                
                                </div>
                                <h1>How Can this Help You?</h1>
                                <ul>
                                    <li className="fade-in"><span>Reduce the probability of Covid-19 presence</span><span>✔️</span></li>
                                    <li className="fade-in"><span>Track attendance of all people by time and date</span><span>✔️</span></li>
                                    <li className="fade-in"><span>Searchable, downloadable data</span><span>✔️</span></li>
                                    <li className="fade-in"><span>Touchless, secure, private data entry using your personal device</span><span>✔️</span></li>
                                    <li className="fade-in"><span>Compatibility enhancement to your existing systems available upon request</span><span>✔️</span></li>
                                </ul>                                
                            </div>
                        </div>                       
                        <div className="homeanchor">
                            <a name="features"> </a>                       
                            <div className="homesection">
                                <h1>Functionality and Features :</h1>

                                <div className="homesidebyside">
                                    <div className="homeSBG">
                                        <img src={PhonePic} alt="Covid Tracing Phone"></img>                        
                                    </div> 
                                    <div className="homesidecontent">
                                        <div className="homebulletin">
                                            <h2>Business</h2>
                                            <ul>
                                                <li className="invisible"><span>Signup includes personalized Icon and QR code</span></li>
                                                <li className="invisible"><span>Secure data access and search on demand</span></li>
                                            </ul>                                             
                                        </div>
                                        <div className="homebulletin">
                                            <h2>Customer</h2>
                                            <ul>
                                                <li className="invisible"><span>Scan QR code with your phone on arrival</span></li>
                                                <li className="invisible"><span>open the web app, answer the form and safely submit</span></li>
                                            </ul>
                                        </div>
                                    </div>                                   
                                </div>
                                <div className="homebulletin">
                                    <br />                                    
                                    <h2>Use Cases</h2>                                    
                                    <div className="singleslide">
                                        <SlideShow
                                            content={slideshowContent}                                            
                                            auto={true}/>
                                    </div>                                           
                                </div>                                 
                            </div>                            
                        </div>
                        <div className="homeanchor">
                            <a name="pricing"> </a>
                            <div className="homesection">
                                <h1>Pricing</h1>
                                <b></b>
                                <b></b>
                                <h3 > It's 100% FREE for the first 200 users!</h3>
                            </div>
                        </div>
                        <div className="homeanchor">
                            <a name="signup"> </a>
                            <div className="homesection signupform">
                           
                                <h1>Sign Up</h1>
                                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSebmUOxzssyvx1O2Ce-kBi_SpoiKhxZ5JOk3YeIOarRXHG2SA/viewform?embedded=true" width="640" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                                
                            </div>
                        </div>
                        <div className="homeanchor">
                            <a name="clientpage"> </a>
                            <div className="homesection">
                           
                                <h1>Members</h1>
                               <h2 className="invisible"><span>To visit your page, please enter your specific Customer-Tracing URL</span> </h2>
                            </div>
                         </div>   
                        <div className="homepolicy purple">
                            <div className="homepolicy-left">Customer Tracing</div>
                            <div className="homepolicy-right"><span>Privacy</span> | <span>Terms of Service</span></div>
                        </div>                                  
                    </div>
                </div>

                <Disclaimer defaultON={true} autoClose={true} />               
            </div>         
        )
    }    
}

export default Home;