import React from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Link, useParams } from 'react-router-dom';
import urls from '../../utility/url';

function Admin(props) {
  let { id } = useParams();
  return (
    <div className="page">
      <Container>
        <div className="paddingTop"></div>
        <Link to={props.isLoggedIn ? urls.userAdminQuery.replace(":id",id) : urls.userAdminLogin.replace(":id",id)}>
          <Button
            className="submitBtn"
            bssize="Large"
          >
            Admin
          </Button>
        </Link>
        
      </Container>
    </div>
  );
}

export default Admin;