export default {
    default: "/",
    home: "/home",
    allLanding: "/client/",
    userLanding: "/client/:id",
    userFaculty: "/client/:id/faculty",
    userStudent: "/client/:id/student",
    userVisitor: "/client/:id/visitor",
    userAdminLogin: "/client/:id/admin",
    userAdminSignUp: "/client/:id/signup",
    userAdminQuery: "/client/:id/query",
    companyRegistration: "/registration",        
};