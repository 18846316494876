import React from "react";
import Container from 'react-bootstrap/Container';
import FourOhFour from '../../containers/display/fourOhFour.js';
import { Link } from 'react-router-dom';
import config from '../../utility/config';
import urls from '../../utility/url';

class Landing extends React.Component {
  
  componentDidMount() {
    this.props.updateCurURL(this.props.match.params.id);
  }

  render() {
    let { id } = this.props.match.params;
    let display;

    if (this.props.isCustomer) {
      display = <div className="page">
      <Container>
        <div className="paddingTop">
        </div>
        <div className="flexParent">
          
          <Link to={urls.userFaculty.replace(":id",id)}>
            <button className="landingBtn">
              Faculty
            </button>
          </Link>
          <Link to={urls.userStudent.replace(":id",id)}>
            <button className="landingBtn">
              Student
            </button>
          </Link>
          <Link to={urls.userVisitor.replace(":id",id)}>
            <button className="landingBtn">
              Visitor
            </button>
          </Link>

          <button className="landingBtn defcursor">
            <img
              src={config.s3.IMGURL+id+".svg"}
              alt="QR Code"
              height="150px"
              width="150px"
              >
            </img>
            Scan for URL
          </button>
          
        </div>
        <br/>
      </Container>
    </div>;
    } else {
      display = <div><FourOhFour/></div>;
    }
    
    return (
      <div>
        {display}
      </div>
      )
  };
}

export default Landing;