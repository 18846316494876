import React from 'react';
import { saveAttendance } from '../../utility/webapi.js';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Symptoms1 from '../../components/questionaire/symptoms1.js';
import Symptoms2 from '../../components/questionaire/symptoms2.js';
import Symptoms3 from '../../components/questionaire/symptoms3.js';
import Symptoms4 from '../../components/questionaire/symptoms4.js';
import Travel from '../../components/questionaire/travel.js';
import SafeDistance from '../../components/questionaire/safe_distance.js';
import Visit from '../../components/questionaire/visit.js';
import Modalbox from '../../components/modalbox/modalbox.js';
import FourOhFour from './fourOhFour.js';
import urls from '../../utility/url';

class Attendee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redflag: ""
        }
    }

    componentDidMount() {
        this.props.updateCurURL(this.props.match.params.id);
    }

    popUp = (popUpID, timer) => {
        const popUp = document.getElementById(popUpID);
        popUp.classList.remove("hidden");
        setTimeout(function () {
            const popUp = document.getElementById(popUpID);
            popUp.classList.add("hidden");
        }, timer)
    }

    popUpConfirm = (popUpID) => {
        const popUp = document.getElementById(popUpID);
        popUp.classList.remove("hidden");
    }

    popUpConfrimClose = () => {
        const popUp = document.getElementById("modalbox1");
        popUp.setAttribute("class", "modalhide");
    }

    handleEvent = () => {
        let redflag = "";
        let [customerAnswers, counter] = saveAttendance(this.props.clientProfile);
        let CACounter = counter
        const { history } = this.props;
        if (CACounter === 0) {
            if ((customerAnswers.Symptoms1 === "Yes") ||
                (customerAnswers.Symptoms2 === "Yes") ||
                (customerAnswers.Symptoms3 === "Yes") ||
                (customerAnswers.Symptoms4 === "Yes") ||
                (customerAnswers.Travel === "Yes") ||
                (customerAnswers.IsolationID === "Yes")) {
                redflag = <div className="btnWrap">
                    You are legally required to self isolate and contact AHS at 811<br />
                    <button className="submitBtn" onClick={() => { this.popUpConfrimClose() }}>Confirm</button>
                </div>
            }

            else {
                redflag = "Thanks for registering.  Please remember to practice safe distancing"

                setTimeout(() => { history.push(urls.userLanding.replace(":id",this.props.match.params.id)) }, 5000);
            }
            this.setState({ "redflag": redflag });
        }

        else {
            this.setState({ "redflag": "" })
        }
    }

    render() {
        let paddingTop;
        let display;
        (this.props.customer === "Faculty") ? paddingTop = "" : paddingTop = "paddingTop"
        if (this.props.isCustomer) {
            display =
            <div>
                <div className="page" id="home">
                    <Container>
                        <div className={paddingTop}></div>
                        <Form.Label>Contact Info</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="FirstNID" placeholder="First Name" required="required" />
                            </Col>
                            <Col>
                                <Form.Control type="text" id="LastNID" placeholder="Last Name" required />
                            </Col>
                        </Row>
                        <Row><br /></Row>
                        <Form.Control type="text" id="PhoneID" placeholder="Phone Number" required />
                        <br />
                        <Form.Control type="email" id="EmailID" placeholder="Email Address" required />
                        <br />
                        <Visit customer={this.props.customer} />
                        <Symptoms1/>
                        <Symptoms2/>
                        <Symptoms3/>
                        <Symptoms4/>
                        <Travel />
                        <SafeDistance />

                        <div className="textDG borderColor green">
                            <em> Privacy Statement: We collect your contact information to inform you
                            of any potential COVID-19 cases associated to this space. Your contact info
                            will only be shared with AHS contact tracing team when requested by them.
                        Your data is stored for 30 days, then deleted.</em>
                        </div>
                        <div className="btnWrap" id="completeForm">
                        </div>

                        <div className="btnWrap">
                            <Button className="submitBtn" bssize="large" todo="Post" onClick={() => { this.handleEvent() }}>SUBMIT</Button><br />
                        </div>
                        <br/><br/>

                        <Modalbox   boxID="modalbox1"
                                    content={<div className="btnWrap">{this.state.redflag}</div>}
                                    hide={this.state.redflag === "" ? true : false}
                        />

                    </Container>
                </div>                
            </div>
        }
        else
        {
            display = <div><FourOhFour/></div>;
        }


        return (
            <div>
                {display}
            </div>
        )
    };
}

export default Attendee;