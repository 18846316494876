import React from "react";
import NewClient from '../../containers/display/newclient.js';

function Registration(props) {
  return (
    <div className="page">
      <NewClient />
      
    </div>
  );
}

export default Registration;