import React from "react";
import './homenav.css';

// https://covidpreauth.com/
// https://www.customer-tracing.com/
// https://www.freecodecamp.org/news/how-to-use-the-position-property-in-css-to-align-elements-d8f49c403a26/
class HomeNav extends React.Component {
    render() {

        return (
            <nav className={`black sticky`}>
                <ul className="main-nav">
                    <li className="nav-left-el">
                        <label className="navlogotext">Customer Tracing</label>
                    </li>
                    <li className="nav-center-el">
                        <a href="#home">Home</a>
                    </li>
                    <li className="nav-center-el">
                        <a href="#goals">Goals</a>
                    </li>
                    <li className="nav-center-el">
                        <a href="#features">Features</a>
                    </li>
                    <li className="nav-center-el">
                        <a href="#pricing">Pricing</a>
                    </li>
                    <li className="nav-center-el">
                        <a href="#signup">Sign Up</a>
                    </li>
                    <li className="nav-center-el">
                        <a href="#clientpage">Members</a>
                    </li>

                    <li className="nav-right-el">
                        <a href="mailto:info@InvSparkTech.ca">Contact Us</a>
                    </li>      
                </ul>                    
            </nav>
        )
    }    
}

export default HomeNav;