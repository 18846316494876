import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import DateDisplay from '../control/date.js';
import Phone from '../../components/control/phone.js';
import Email from '../../components/control/email.js';
import Name from '../../components/control/name.js';
import Submit from '../control/submit.js';
import FourOhFour from './fourOhFour.js';
import { Link } from 'react-router-dom';
import urls from '../../utility/url';

class Query extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "" //Email, Date, Name, Phone_Number
        }
    }

    changeDisplay = (display) => {
        this.setState({
            "display": display
        });
    }

    render() {
        let clientpage;
        const id = this.props.match.params.id;

        if (this.props.cognitoUserName !== "") {
            clientpage = <div className="page" id="home">

                <Container>
                    <div className="paddingTop"></div>                                       

                    Client Query

                    <Link to={urls.userLanding.replace(":id",id)}>
                        <Button className="submitBtn right" variant="primary" onClick={() => this.props.setLoggedIn(false)}>Log Out</Button>
                    </Link>
                    
                    <br/><br/>
                    <Button className="submitBtn" variant="primary" onClick={() => this.changeDisplay("Date")}>Date</Button>{' '}
                    <Button className="submitBtn" variant="primary" onClick={() => this.changeDisplay("Email")}>Email</Button>{' '}
                    <Button className="submitBtn" variant="primary" onClick={() => this.changeDisplay("Phone_Number")}>Phone</Button>{' '}
                    <Button className="submitBtn" variant="primary" onClick={() => this.changeDisplay("Name")}>Name</Button>{' '}
                    <Button className="submitBtn" variant="primary" onClick={() => this.changeDisplay("All_Data")}>All Data</Button>{' '}<br /><br />
                    {this.state.display === "Date" &&
                        <div>
                            <DateDisplay />
                            <Submit
                                displayState={this.state.display}
                                clientProfile={this.props.clientProfile}
                            />
                        </div>
                    }
                    {this.state.display === "Email" &&
                        <div>
                            <Email />
                            <Submit
                                displayState={this.state.display}
                                clientProfile={this.props.clientProfile}
                            />
                        </div>
                    }
                    {this.state.display === "Phone_Number" &&
                        <div>
                            <Phone />
                            <Submit
                                displayState={this.state.display}
                                clientProfile={this.props.clientProfile}                        
                            />
                        </div>
                    }
                    {this.state.display === "Name" &&
                        <div>
                            <Name />
                            <Submit
                                displayState={this.state.display}
                                clientProfile={this.props.clientProfile}                            
                            />
                        </div>
                    }

                    {this.state.display === "All_Data" &&
                        <div>
                            <p>Press submit to retrieve all data from database</p>
                            <Submit
                                displayState={this.state.display}
                                clientProfile={this.props.clientProfile}
                            />
                        </div>
                    }

                    <br />

                </Container>

            </div>
        } else {
            clientpage = <div><FourOhFour/></div>
        }

        return (
            <div>
                {clientpage}
            </div>
        )
    }
}

export default Query