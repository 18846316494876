import React from 'react';
import Form from 'react-bootstrap/Form';

function Visit(props) {
  // console.log(props.customer)
  let reasonValue;
  let returnDisplay;
  if (props.customer !== "Reason for visit") {
    reasonValue = props.customer // "Faculty" "Student"
    returnDisplay =
      <div className="borderColor reasonText" >
        <Form.Label>Reason for visit</Form.Label>
        <Form.Control
          readOnly
          type="text"
          id="ReasonID"
          defaultValue={reasonValue}
        />
      </div>
  } else {
    returnDisplay =
      <div className="borderColor reasonText" >
        <Form.Label>Reason for visit</Form.Label>
        <Form.Control
          type="text"
          id="ReasonID"
          placeholder={props.customer}
          value={reasonValue}
        />
      </div>
  }

  return (
    <div>
      {returnDisplay}
    </div>
  );
}

export default Visit;