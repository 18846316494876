import React from 'react';
import { get } from '../../utility/utility.js';
import { getAttendance } from '../../utility/webapi.js';
import { JsonToTable } from "react-json-to-table";
import { TableExport } from "tableexport";

class Submit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            table: {}
        }
    }
    
    buildQuery = async (displayState) => {
        // Send JSON obj to API/client route
        const query = {};
        query.todo = displayState;

        if (displayState === "Date") {
            query.Start_Date = get("Start_DateID");
            query.End_Date = get("End_DateID");
        }

        if (displayState === "Email") {
            query.Email = get("EmailQueryID");
        }

        if (displayState === "Phone_Number") {
            query.Phone_Number = get("PhoneQueryID");
        }

        if (displayState === "Name") {
            query.First_Name = get("First_NameID");
            query.Last_Name = get("Last_NameID");
        }

        query.CompanyID = this.props.clientProfile["CompanyID"];

        const queryResponse = await getAttendance(query);
        this.setState({ "table": queryResponse })

        let TEC = document.getElementsByClassName("tableexport-caption").item(0);
        if (TEC) {
            while (TEC.firstChild) {
                TEC.removeChild(TEC.firstChild);
            }
        };
        TableExport(document.getElementsByTagName("table"));
        let addClassName = document.getElementsByClassName("tableexport-caption");
        
        for (let x = 0; x < addClassName[0].children.length; x++) {
            addClassName[0].childNodes[x].classList.add("submitBtn")
            addClassName[0].childNodes[x].classList.add("btnMargin")
        }
    }

    render() {

        return (
            <div className="btnWrap">
                <button className="submitBtn" todo="Post" onClick={() => this.buildQuery(this.props.displayState)}>SUBMIT</button><br /><br />
                <div className="table">
                    <JsonToTable json={this.state.table} />
                </div>
            </div >
        )
    }
}

export default Submit