import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Symptoms2() {
  return (
      <div className="borderColor" id="SymptomsID2">
        {/* <form > */}
        <Row >
          <Col>
            <label>Do you have any of these following symptoms (continued)?</label>
          </Col>
          <Col>
                <div className="radio">
                  <label>
                    <input 
                    name="SymptomsID2" 
                      type="radio"
                      value="Yes"
                    />
                      {' Yes'}
                    </label>
                  <label className="ml-4">
                    <input
                    name="SymptomsID2"
                      type="radio"
                      value="No"
                    />
                      {' No'}
                    </label>
                </div>
          </Col>
        </Row>
      <p className="ml-3 textDG">Shortness of breath at rest, inability to lie down
      because of difficulty breathing, chronic health conditions that you are having
        difficulty managing because of your current respiratory illness?</p>
    </div>
    );
  
}

export default Symptoms2;