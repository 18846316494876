export default {
  s3: {
    REGION: "ca-central-1",
    BUCKET: "customer-tracing-dev",
    IMGURL: "https://customer-tracing-dev.s3.ca-central-1.amazonaws.com/public/"
  },  
  apiGateway: {
    REGION: "ca-central-1",
    URL: "https://de80tvzgqa.execute-api.ca-central-1.amazonaws.com/DevEnv"
  },
  cognito: {
    REGION: "ca-central-1",
    IDENTITY_POOL_ID: 'ca-central-1:fe0e94ca-9292-4893-ba95-65ecd379f794',
    USER_POOL_ID: "ca-central-1_uMXGOQmUv",
    APP_CLIENT_ID: "6po81m6rfrriruin0t8kob7rr8"
  },
};