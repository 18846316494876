import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Symptoms4() {
  return (
      <div className="borderColor" id="SymptomsID4">
        {/* <form > */}
        <Row >
          <Col>
            <label>Do you have any of these following symptoms (continued)?</label>
          </Col>
          <Col>
                <div className="radio">
                  <label>
                    <input 
                    name="SymptomsID4" 
                      type="radio"
                      value="Yes"
                    />
                      {' Yes'}
                    </label>
                  <label className="ml-4">
                    <input
                    name="SymptomsID4"
                      type="radio"
                      value="No"
                    />
                      {' No'}
                    </label>
                </div>
          </Col>
        </Row>
      <p className="ml-3 textDG">Chills, painful swallowing, stuffy nose, headache,
      muscle or joint ache, feeling unwell, fatigue or severe exhaustion, nausea,
      vomiting, diarrhea or unexplained loss of appetite, loss of sense of smell
      or taste, conjunctivitis (pink eye)?</p>
    </div>
  );
  
}

export default Symptoms4;