import React from "react";
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

function DefaultPage(props) {

  return (
    <div className="page">
      <Container>        
        Please visit your client page for attendance or our <Link to="/home" onClick={() => props.setHome(true)}>home</Link> page for more information.
      </Container>
    </div>
  );
}

export default DefaultPage;