import React from "react";
import Admin from './admin.js';
import Attendee from '../../containers/display/attendee.js';
import FourOhFour from '../../containers/display/fourOhFour.js';

class Employee extends React.Component {

    componentDidMount() {
        this.props.updateCurURL(this.props.match.params.id);
    }

    render() {

        let display;

        if (this.props.isCustomer) {
            display = 
            <div>
                <Admin {...this.props} isLoggedIn={this.props.isLoggedIn}/>
                <Attendee {...this.props} isCustomer={this.props.isCustomer} clientProfile={this.props.clientProfile} customer={this.props.customer}/>
            </div>
        }
        else {
            display = <div><FourOhFour/></div>;
        }

        return (
            <div>
                {display}
            </div>
        )        
    };
  }
  
  export default Employee;