import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import { Spinner } from "react-bootstrap";

function FourOhFour(props) {
  const [msg, setMsg] = useState(<Spinner animation="border" role="status"/>);
  
  setTimeout(() => {
    setMsg("404 File not found");
  }, 500)

  return (
    <div className="page">
      <Container>
        <br/><br/><br/>
        {msg}
      </Container>
    </div>
  );
}

export default FourOhFour;