import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DateDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
        };
    }

    handleChangeStart = date => {
        this.setState({
            startDate: date
        });
    };
    handleChangeEnd = date => {
        this.setState({
            endDate: date
        });
    };
    render() {
        return (
            <div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Start Date</span>
                    </div>
                    <DatePicker
                        id="Start_DateID"
                        className="form-control borderleftnone"
                        selected={this.state.startDate}
                        onChange={this.handleChangeStart}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">End Date</span>
                    </div>
                    <DatePicker
                        id="End_DateID"
                        className="form-control borderleftnone"
                        selected={this.state.endDate}
                        onChange={this.handleChangeEnd}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
                    {/* <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"> */}
            </div>
        )
    }


}

export default DateDisplay