import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Symptoms3() {
  return (
      <div className="borderColor" id="SymptomsID3">
        {/* <form > */}
        <Row >
          <Col>
            <label>Do you have any of these following symptoms (continued)?</label>
          </Col>
          <Col>
                <div className="radio">
                  <label>
                    <input 
                    name="SymptomsID3" 
                      type="radio"
                      value="Yes"
                    />
                      {' Yes'}
                    </label>
                  <label className="ml-4">
                    <input
                    name="SymptomsID3"
                      type="radio"
                      value="No"
                    />
                      {' No'}
                    </label>
                </div>
          </Col>
        </Row>
      <p className="ml-3 textDG">Fever, new onset of cough or worsening of chronic cough, 
      new or worsening shortness of breath, new or worsening difficulty breathing,
        sore throat, runny nose?</p>
    </div>
  );
  
}

export default Symptoms3;