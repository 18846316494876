import React, { Fragment } from "react";
import './disclaimer.css';

class Disclaimer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disclaimerON: this.props.defaultON
        }
    }
        
    componentDidMount() {
        if(this.props.autoClose) {
            setTimeout(() => {
                this.toggleDisclaimer(false);
            }, 5000);
        }
    }

    toggleDisclaimer(bool) {
        this.setState({ "disclaimerON": bool })
    }

    render() {

        return (
            <React.Fragment>
                <div className={`disclaimer ${this.state.disclaimerON ? "disclaimerON" : "disclaimerOFF"}`}>
                    <div className="disclaimer-content">
                        <h5>Disclaimer</h5>
                        <span>
                            THIS WEBSITE DOES NOT PROVIDE MEDICAL ADVICE. In no event shall the information
                            contained on this website be construed as the rendering by Customer Tracing App, its related 
                            entities, or the owners, agents, or employees thereof of professional medical advice 
                            or services. The information contained on this website is not intended to be a 
                            substitute for or to be relied upon as, medical advice, diagnosis, or treatment. 
                            This website is for informational purposes only. Before making any decision or taking 
                            any action regarding your medical health, always seek the advice of your physician or other 
                            qualified health providers with any questions you may have regarding a medical condition. 
                            <br/>                             
                        </span>
                        <div className="ovalbutton" onClick={()=>this.toggleDisclaimer(false)}>CLOSE</div>
                    </div>
                </div>

                <div className={`minbutton ${!this.state.disclaimerON ? "minbuttonON" : "minbuttonOFF"}`} onClick={()=>this.toggleDisclaimer(true)}>
                    <div>Disclaimer</div>
                </div>                
            </React.Fragment>         
        )
    }    
}

export default Disclaimer;