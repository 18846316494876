import React from 'react';
import { Storage } from 'aws-amplify';

// https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-photos-view.html
class S3ImageUpload extends React.Component {
    onChange(e) {
        const file = e.target.files[0];

        if (file) {
            Storage.put(this.props.filename, file, {
                level: 'public',
                contentType: this.props.contentType
            })
            .then (result => console.log(result))
            .catch(err => console.log(err));
        }
    }
  
    render() {
        return (
            <input
                type="file" accept={this.props.contentType}
                onChange={(evt) => this.onChange(evt)}
                disabled={this.props.disabled}
            />
        )
    }
  }

  export default S3ImageUpload;