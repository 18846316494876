import React from "react";
import './slideshow.css';

// https://www.w3schools.com/howto/howto_js_slideshow.asp
class SlideShow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slideIndex: 1
        }
    }

    componentDidMount() {
        this.showSlides(this.state.slideIndex);

        this.autoSlide(this.props.auto, 3000);
    }        

    autoSlide(auto, interval) {
        if (auto) {
            setInterval (() => {
                this.plusSlides(1);
            }, interval)
        }
    }

    // Next/previous controls
    plusSlides(n) {
        let i = this.state.slideIndex + n;
        this.showSlides(i);
    }

    // Thumbnail image controls
    currentSlide(n) {
        this.showSlides(n);
    }

    showSlides(n) {
        const slidecontent = this.props.content; 
        let i;
        let index = n;

        if (slidecontent) {
            let slides = document.getElementsByClassName("mySlides");
            let dots = document.getElementsByClassName("dot");
    
            if (n > slides.length) {index = 1}
            if (n < 1) {index = slides.length}
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {            
                dots[i].className = dots[i].className.replace(" active", "");
                console.log(i, dots[i].className);
            }
            slides[index-1].style.display = "block";        
            dots[index-1].className += " active";
    
            this.setState({ "slideIndex": index });
        }
    }

    render() {
        const slides = [];
        const dotcontrol = [];
        const slidecontent = this.props.content;        

        if (slidecontent) {
            for (let i=0; i<slidecontent.length; i++) {
                slides.push(<Slide 
                                index={i+1} length={slidecontent.length} 
                                content={slidecontent[i]["content"]} captiontext={slidecontent[i]["captiontext"]}
                            />);

                dotcontrol.push(<span className="dot" onClick={()=>this.currentSlide(i+1)}></span>);                
            }
        }
        else {
            dotcontrol.push(<span className="dot" onClick={()=>this.currentSlide(1)}></span>);
        }

        return (
            <div className="slideshow-component">
                {/* <!-- Slideshow container --> */}
                <div className="slideshow-container">
                    <div className="slideshow-deck">
                        {/* <!-- Full-width images with number and caption text --> */}
                        {slides}
                    </div>
                    {/* <!-- Next and previous buttons --> */}
                    <p className="prev" onClick={()=>this.plusSlides(-1)}>&#10094;</p>
                    <p className="next" onClick={()=>this.plusSlides(1)}>&#10095;</p>
                </div>                

                {/* <!-- The dots/circles --> */}
                <div className="dot-container">
                    {dotcontrol}
                </div>                
            </div>
        )
    } 
}

class Slide extends React.Component {
    render() {
        return (
            <div className="mySlides slideshow-fade">
                <div className="numbertext">{this.props.index} / {this.props.length}</div>
                {this.props.content}
                <div className="captiontext">{this.props.captiontext}</div>
            </div>
        )
    }
}

export default SlideShow;