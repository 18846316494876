import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Attendee from './display/attendee.js';
import Query from './display/query.js';
import Login from './authentication/login.js';
import SignUp from './authentication/signup.js';
import Landing from '../components/display/landing.js';
import FourOhFour from './display/fourOhFour.js';
import DefaultPage from '../components/display/defaultpage.js';
import Registration from '../components/display/registration.js';
import Employee from '../components/display/employee.js';
import Home from "../components/display/home.js";
import AppNav from "../components/control/appnav.js";
import HomeNav from "../components/control/homenav.js";
import { getClient } from '../utility/webapi';
import config from '../utility/config';
import urls from '../utility/url';

// *** import route
import { Route, Link, Switch } from 'react-router-dom';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      isCustomer: false,
      isHome: false,
      clientList: [],
      clientProfile: {},
      isLoggedIn: false,
      admin: false,
      cognitoUserName: "",
      id: "",
    }
  }

  componentDidMount() {
    // Get a list of valid clients from server
    this.loadClient();
  }

  componentDidUpdate() {        
    // Confirm client is valid by verifying endpoint provided by child component
    // after initial update of valid client list
    if (!this.state.isCustomer && this.state.clientList.length > 0 && this.state.id !== "") {
      this.confirmCustomer(this.state.id);            
    }    
  }

  async loadClient(event) {
    try {
        const data = await getClient()

        if (typeof(data["body"]) != "string") {
          this.setState({
            "clientList": data["body"]
          });
        }
    } catch (error) {

    }
  }   

  setLoggedIn = (bool) => {    
    this.setState({
      "isLoggedIn": bool
    });
  }

  setHome = (bool) => {
    this.setState({
      "isHome": bool
    });
  }

  cognitoUserName = (cogName) => {
    this.setState({
      "cognitoUserName": cogName
    });
  }

  updateCustomer = (bool, clientInfo) => {
    this.setState({
      "isCustomer": bool,
      "clientProfile": clientInfo
    })
  }
  
  updateCurURL = (routeParam) => {
    this.setState({
      "id": routeParam
    })
  }

  confirmCustomer = (routeParam) => {
    const urlEnd = this.state.clientList.map(x => x["UrlEnd"]);
    const index = urlEnd.indexOf(routeParam);

    if (index > -1) {
      this.updateCustomer(true, this.state.clientList[index]);
    }
  }

  render() {
    const logo = this.state.isCustomer ?  <img src={config.s3.IMGURL+this.state.id+".png"} className="App-image" alt={this.state.id} /> : "";
    const navbar = this.state.isHome ? <HomeNav /> : <AppNav url={urls.userLanding.replace(":id",this.state.id)} logo={logo}/>

    return (
      <div>
        {navbar}

        <Switch>
          <Route exact path={urls.userLanding}
           render={(props) => (
            <Landing {...props}
              clientList={this.state.clientList}
              isCustomer={this.state.isCustomer}
              updateCurURL={this.updateCurURL}
            />
           )}
          />

          <Route path={urls.userFaculty}
            render={(props) => (
              <Employee {...props} 
                isCustomer={this.state.isCustomer}
                clientProfile={this.state.clientProfile}
                updateCurURL={this.updateCurURL}
                customer="Faculty"                
                isLoggedIn={this.state.isLoggedIn}                
              />
            )}
          />

          <Route path={urls.userStudent}
            render={(props) => (
              <Attendee {...props} 
                isCustomer={this.state.isCustomer}
                clientProfile={this.state.clientProfile}
                updateCurURL={this.updateCurURL}
                customer="Student" 
              />            
            )}
          />

          <Route path={urls.userVisitor}
            render={(props) => (
              <Attendee {...props}
                isCustomer={this.state.isCustomer}
                clientProfile={this.state.clientProfile}
                updateCurURL={this.updateCurURL}
                customer="Reason for visit" 
              />
            )}
          />

          <Route path={urls.userAdminLogin}
            render={(props) => (
              <Login {...props}                
                isCustomer={this.state.isCustomer}
                updateCurURL={this.updateCurURL}              
                cognitoUserName={this.cognitoUserName}
                setLoggedIn={this.setLoggedIn}                
              />            
            )}
          />

          <Route path={urls.userAdminSignUp}
            render={(props) => (
              <SignUp {...props}
                isCustomer={this.state.isCustomer}                
                updateCurURL={this.updateCurURL}
              />            
            )}
          />

          <Route path={urls.userAdminQuery}
            render={(props) => (
              <Query {...props}
                cognitoUserName={this.state.cognitoUserName}
                clientProfile={this.state.clientProfile}
                setLoggedIn={this.setLoggedIn}/>            
            )}
          />

          <Route path={urls.home,urls.default}
            render={(props) => (
              <Home {...props}
                setHome={this.setHome}/>            
            )}
          /> 

            {/* <Route path={urls.defaultpage}   */}
              {/* render={(props) => (   */}
                {/* <DefaultPage {...props}   */}
                  {/* setHome={this.setHome}/>               */}
             {/* )} */}
             {/* />           */}

          <Route path={urls.companyRegistration}
            component={Registration}
          />

          <Route component={FourOhFour}/>
        </Switch>

      </div>
    )
  };
}

export default App;
