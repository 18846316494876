import React, { useState, useEffect } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./login.css";
import { Auth } from "aws-amplify";
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import FourOhFour from '../display/fourOhFour.js';
import urls from '../../utility/url';

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [verifiedCode, setVerifiedCode] = useState("");
  const [verifiedCodeIsRequired, setVerifiedCodeIsRequired] = useState(false);  
  const history = useHistory();
  const { id } = useParams();

  // Similar to componentDidMount and componentDidUpdate:
  // only update when id is changed
  useEffect(() => {
    props.updateCurURL(id);
  },[id]);

  function validateForm() {
    const verificationCodeCheck = verifiedCodeIsRequired ? (verifiedCode !== "") : true;
    return email.length > 0 && password.length > 0 && verificationCodeCheck;
  }
  
  async function handleSignIn(event) {
    event.preventDefault();
    try {
      let msg = "Login successful.";

      if (verifiedCodeIsRequired)
      {
        await Auth.confirmSignUp(email, verifiedCode);
        msg = "Your registration has been successfully confirmed!";
      }

      let auth = await Auth.signIn(email, password);
      props.cognitoUserName(auth.username);
      history.push(urls.userAdminQuery.replace(":id",id));

      props.setLoggedIn(true);
      setErrormsg(msg);      
    } catch (e) {
      console.log("Login exception", e);

      switch(e.code) {
        case "UserNotConfirmedException":
          setErrormsg("Enter Verification Code.");
          setVerifiedCodeIsRequired(true);
          break;
        case "CodeMismatchException":
          setErrormsg(e.message);
          break;
        default:
          setErrormsg("Login failed.");
      }      
    }
  }
    
  let display; 

  if (props.isCustomer) {
    display = 
      <Container>
        <form>
          <div className="paddingTop"></div>      
          <FormGroup controlId="email" bssize="large">
            <FormLabel>Email</FormLabel>
            <FormControl
              autoFocus
              // id="loginEmail"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="password" bssize="large">
            <FormLabel>Password</FormLabel>
            <FormControl
              // id="loginPassword"
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
            />
          </FormGroup>
          <div className="loginerror">
            {errormsg}
          </div>

          {
            verifiedCodeIsRequired ? 
              <FormGroup controlId="verifiedcode" bssize="large">
              <FormLabel>Verification Code</FormLabel>
              <FormControl
                value={verifiedCode}
                onChange={e => setVerifiedCode(e.target.value)}
                type="verifiedcode"
              />
              </FormGroup>        
            : ""
          }

          <Button id="login" className="submitBtn" block bssize="large" disabled={!validateForm()} type="submit" onClick={handleSignIn}>
            Login
          </Button>      
          {/* <br/>
          <span>or</span>
          <br/>
          <br/>
          <Link to={urls.userAdminSignUp.replace(":id",id)}>
            <Button id="signup" className="submitBtn" block bssize="large" type="submit">
              New User
            </Button>
          </Link>       */}
          <br/>
        </form>
      </Container>;
  } else {
    display = <div><FourOhFour/></div>;
  }

  return (
    <div className="page Login">
      {display}
    </div>
  );
}