import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import './appnav.css';

class AppNav extends React.Component {
    render() {

        return (
            <Navbar sticky="top" >
                <Link to={this.props.url}>
                <Navbar.Brand>Customer Tracing</Navbar.Brand>
                </Link>
                {this.props.logo}
                <div className="navText"> 
                protecting health through contact tracing                         
                </div>
            </Navbar>      
        )
    }    
}

export default AppNav;