import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Symptoms1() {
  return (
      <div className="borderColor" id="SymptomsID1">
        {/* <form > */}
        <Row >
          <Col>
            <label>Do you have any of these following symptoms?</label>
          </Col>
          <Col>
                <div className="radio">
                  <label>
                    <input 
                    name="SymptomsID1" 
                      type="radio"
                      value="Yes"
                    />
                      {' Yes'}
                    </label>
                  <label className="ml-4">
                    <input
                    name="SymptomsID1"
                      type="radio"
                      value="No"
                    />
                      {' No'}
                    </label>
                </div>
          </Col>
      </Row>
      <p className="ml-3 textDG">Severe difficulty breathing (e.g., struggling for each breath,
      speaking in single words), severe chest pain,having a very hard time waking up, 
      feeling confused, lost consciousness?</p>
    </div>
  );
  
}

export default Symptoms1;