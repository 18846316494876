function get(id) {
    let docvalue = document.getElementById(id).value
    if (docvalue.length !== 0) {
        return docvalue;
    } else {
        let el = document.getElementById(id)
        el.classList.add("borderColorRed");
        return("not valid")
    }        
}

function getSelection(id) {
    try {
        return document.querySelector("input[name=" + id + "]:checked").value;
    }
    catch (err) { 
        let el = document.getElementById(id)
        el.classList.add("borderColorRed");       
        return("not valid")
    }
}

export { get, getSelection };