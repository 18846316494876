import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import S3ImageUpload from '../../components/control/upload';
import { addNewClient } from '../../utility/webapi';

function NewClient(props) {
    const [companyName, setCompanyName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [submitToken, setSubmitToken] = useState(1);
    const [msg, setMsg] = useState("");

    function validateForm() {
        return submitToken > 0 && companyName.length > 0 && displayName.length > 0;
    }

    function generateURL() {
        let url = displayName.toLowerCase();
        let arr = [" ", "'", '"', ";", ",", "*", "%", "?"]
        for(let i=0; i<arr.length; i++) {
            url = url.replace(arr[i],"");
        }        

        return url;
    }

    function generateFilename(ext) {
        let filename = generateURL() + ext;
        return filename;
    }      

    async function handleSubmit(event) {
        try {
            let data = {
                        "CompanyName": companyName,
                        "DisplayName": displayName,
                        "UrlEnd": generateURL(),
                        };

            await addNewClient(data);
            setMsg("Created new company successfully.");
            setSubmitToken(0);

        } catch (error) {
            setMsg("Failed in company creation", error);
        }
    }

    return (
        <div className="page">
            <Container>
                <div className="paddingTop"></div>
                <h1>Create a New Client</h1>

                <FormGroup controlId="CompanyName" bssize="large">
                    <FormLabel>Company Name</FormLabel>
                    <FormControl
                    autoFocus
                    type="companyname"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                    />
                </FormGroup>

                <FormGroup controlId="DisplayName" bssize="large">
                    <FormLabel>Display Name</FormLabel>
                    <FormControl
                    autoFocus
                    type="displayname"
                    value={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                    />
                </FormGroup>              

                <FormLabel>Company Icon</FormLabel>
                <br/>
                <S3ImageUpload filename={generateFilename(".png")} contentType="image/*" disabled={!validateForm()}/>
                <br/>
                <br/>

                <FormLabel>QR Code</FormLabel>
                <br/>
                <S3ImageUpload filename={generateFilename(".svg")} contentType="image/svg+xml" disabled={!validateForm()}/>
                <br/>
                <br/>                

                <Button id="login" className="submitBtn" block bssize="large" disabled={!validateForm()} type="submit" onClick={handleSubmit}>
                    Submit
                </Button>                  

                <br/>
                <br/>
                <div className="loginerror">
                    {msg}
                </div>                
            </Container>
        </div>
    );
}

export default NewClient;